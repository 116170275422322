import AddToCart from '~/themes/base/components/cart/AddToCart'

export default {
  extends: AddToCart,
  props: {
    charityProduct: Boolean,
  },
  methods: {
    async addToCart(variantId) {
      if (this.$store.state.globals.cartConfiguration.shopping_disabled) {
        notifications.warning(this.$store.state.globals.cartConfiguration.shopping_disabled_text)
        return
      }

      this.startLoading()
      const dataToSent = {
        id: variantId,
        quantity: this.quantity,
        forCredits: this.forCredits,
        ...(this.addToCartCustomData ? { custom: this.addToCartCustomData } : {}),
        ...(this.addToCartNewLine ? { newLine: this.addToCartNewLine } : {}),
      }
      const data = await this.$store.dispatch('cart/ADD_TO_CART', {
        id: variantId,
        quantity: this.quantity,
        forCredits: this.forCredits,
        ...(this.addToCartCustomData ? { custom: this.addToCartCustomData } : {}),
        ...(this.addToCartNewLine ? { newLine: this.addToCartNewLine } : {}),
      })
      if (data && data.addedItem) {
        this.addedItem = { ...data.addedItem }
        this.changedAmount = this.charityProduct ? 1 : data.changedAmount

        if (this.charityProduct) {
          const prices = { ...this.addedItem.prices }
          prices.unitWithTax = prices.totalWithTax
          this.addedItem.prices = prices
        }

        this.$refs.addToCartModalChooseVariant && this.$refs.addToCartModalChooseVariant.hide()
        this.openAddToCartModalItemAdded()
      }
      this.stopLoading()
    },
  },
}
