import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4ccbc34e = () => interopDefault(import('../sites/kolorky/pages/user/activate.vue' /* webpackChunkName: "sites/kolorky/pages/user/activate" */))
const _7a768859 = () => interopDefault(import('../sites/kolorky/pages/articleList/index.vue' /* webpackChunkName: "sites/kolorky/pages/articleList/index" */))
const _7a79e0f9 = () => interopDefault(import('../sites/kolorky/pages/search/index.vue' /* webpackChunkName: "sites/kolorky/pages/search/index" */))
const _e962b876 = () => interopDefault(import('../sites/kolorky/pages/contact/index.vue' /* webpackChunkName: "sites/kolorky/pages/contact/index" */))
const _6dc3a597 = () => interopDefault(import('../sites/kolorky/pages/checkout/index.vue' /* webpackChunkName: "sites/kolorky/pages/checkout/index" */))
const _010f243b = () => interopDefault(import('../sites/kolorky/pages/faq/index.vue' /* webpackChunkName: "sites/kolorky/pages/faq/index" */))
const _67d49972 = () => interopDefault(import('../sites/kolorky/pages/about/index.vue' /* webpackChunkName: "sites/kolorky/pages/about/index" */))
const _3aa5e507 = () => interopDefault(import('../sites/kolorky/pages/compare.vue' /* webpackChunkName: "sites/kolorky/pages/compare" */))
const _1df0300b = () => interopDefault(import('../sites/kolorky/pages/login.vue' /* webpackChunkName: "sites/kolorky/pages/login" */))
const _9f902e1e = () => interopDefault(import('../sites/kolorky/pages/register.vue' /* webpackChunkName: "sites/kolorky/pages/register" */))
const _dad416d4 = () => interopDefault(import('../sites/kolorky/pages/passwordReset.vue' /* webpackChunkName: "sites/kolorky/pages/passwordReset" */))
const _7f0b13d9 = () => interopDefault(import('../sites/kolorky/pages/sitemapconfig.vue' /* webpackChunkName: "sites/kolorky/pages/sitemapconfig" */))
const _223fbf35 = () => interopDefault(import('../sites/kolorky/pages/maintenance.vue' /* webpackChunkName: "sites/kolorky/pages/maintenance" */))
const _3d036cdc = () => interopDefault(import('../sites/kolorky/pages/user/index.vue' /* webpackChunkName: "sites/kolorky/pages/user/index" */))
const _4623e938 = () => interopDefault(import('../sites/kolorky/pages/warrantyClaims/index.vue' /* webpackChunkName: "sites/kolorky/pages/warrantyClaims/index" */))
const _5851d327 = () => interopDefault(import('../sites/kolorky/pages/warrantyClaims/thanks.vue' /* webpackChunkName: "sites/kolorky/pages/warrantyClaims/thanks" */))
const _6f2bcd22 = () => interopDefault(import('../sites/kolorky/pages/warrantyClaims/form.vue' /* webpackChunkName: "sites/kolorky/pages/warrantyClaims/form" */))
const _5d8b200c = () => interopDefault(import('../sites/kolorky/pages/warrantyClaims/info.vue' /* webpackChunkName: "sites/kolorky/pages/warrantyClaims/info" */))
const _304104d1 = () => interopDefault(import('../sites/kolorky/pages/warrantyClaims/recap.vue' /* webpackChunkName: "sites/kolorky/pages/warrantyClaims/recap" */))
const _26efb061 = () => interopDefault(import('../sites/kolorky/pages/passwordForgotten.vue' /* webpackChunkName: "sites/kolorky/pages/passwordForgotten" */))
const _2af1e215 = () => interopDefault(import('../sites/kolorky/pages/auth/proxy.vue' /* webpackChunkName: "sites/kolorky/pages/auth/proxy" */))
const _7560190e = () => interopDefault(import('../sites/kolorky/pages/checkout/info.vue' /* webpackChunkName: "sites/kolorky/pages/checkout/info" */))
const _15c0fbbd = () => interopDefault(import('../sites/kolorky/pages/checkout/finished.vue' /* webpackChunkName: "sites/kolorky/pages/checkout/finished" */))
const _77c77d92 = () => interopDefault(import('../sites/kolorky/pages/checkout/methods.vue' /* webpackChunkName: "sites/kolorky/pages/checkout/methods" */))
const _19419b9e = () => interopDefault(import('../sites/kolorky/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/kolorky/pages/checkout/importOrder/index" */))
const _5b310e4c = () => interopDefault(import('../sites/kolorky/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/kolorky/pages/user/shoppingLists/index" */))
const _3a9bbde4 = () => interopDefault(import('../sites/kolorky/pages/user/orders/index.vue' /* webpackChunkName: "sites/kolorky/pages/user/orders/index" */))
const _4d85e160 = () => interopDefault(import('../sites/kolorky/pages/user/favourites.vue' /* webpackChunkName: "sites/kolorky/pages/user/favourites" */))
const _40a9ed89 = () => interopDefault(import('../sites/kolorky/pages/user/settings.vue' /* webpackChunkName: "sites/kolorky/pages/user/settings" */))
const _5cec16c4 = () => interopDefault(import('../sites/kolorky/pages/user/dashboard.vue' /* webpackChunkName: "sites/kolorky/pages/user/dashboard" */))
const _dd3c4588 = () => interopDefault(import('../sites/kolorky/pages/user/addressList.vue' /* webpackChunkName: "sites/kolorky/pages/user/addressList" */))
const _429cf001 = () => interopDefault(import('../sites/kolorky/pages/user/password.vue' /* webpackChunkName: "sites/kolorky/pages/user/password" */))
const _2fb8d079 = () => interopDefault(import('../sites/kolorky/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/kolorky/pages/checkout/payment/callback" */))
const _54488cf4 = () => interopDefault(import('../sites/kolorky/pages/index.vue' /* webpackChunkName: "sites/kolorky/pages/index" */))
const _2f51e9c2 = () => interopDefault(import('../sites/kolorky/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/kolorky/pages/user/shoppingLists/_id" */))
const _22d10ef6 = () => interopDefault(import('../sites/kolorky/pages/user/orders/_id.vue' /* webpackChunkName: "sites/kolorky/pages/user/orders/_id" */))
const _5c7517d3 = () => interopDefault(import('../sites/kolorky/pages/article/_slug.vue' /* webpackChunkName: "sites/kolorky/pages/article/_slug" */))
const _78bfe711 = () => interopDefault(import('../sites/kolorky/pages/articleList/_slug.vue' /* webpackChunkName: "sites/kolorky/pages/articleList/_slug" */))
const _2529348c = () => interopDefault(import('../sites/kolorky/pages/product/_slug.vue' /* webpackChunkName: "sites/kolorky/pages/product/_slug" */))
const _e6f0c550 = () => interopDefault(import('../sites/kolorky/pages/page/_slug.vue' /* webpackChunkName: "sites/kolorky/pages/page/_slug" */))
const _b128a9fe = () => interopDefault(import('../sites/kolorky/pages/_.vue' /* webpackChunkName: "sites/kolorky/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _4ccbc34e,
    name: "user-activate___cs"
  }, {
    path: "/clanky",
    component: _7a768859,
    name: "articleList___cs"
  }, {
    path: "/hledat",
    component: _7a79e0f9,
    name: "search___cs"
  }, {
    path: "/kontakt",
    component: _e962b876,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _6dc3a597,
    name: "checkout___cs"
  }, {
    path: "/nejcastejsi-dotazy",
    component: _010f243b,
    name: "faq___cs"
  }, {
    path: "/o-kolorkach",
    component: _67d49972,
    name: "about___cs"
  }, {
    path: "/porovnat",
    component: _3aa5e507,
    name: "compare___cs"
  }, {
    path: "/prihlasit",
    component: _1df0300b,
    name: "login___cs"
  }, {
    path: "/registrovat",
    component: _9f902e1e,
    name: "register___cs"
  }, {
    path: "/resetovat-heslo",
    component: _dad416d4,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _7f0b13d9,
    name: "sitemapconfig___cs"
  }, {
    path: "/udrzba",
    component: _223fbf35,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _3d036cdc,
    name: "user___cs"
  }, {
    path: "/vymena-vraceni",
    component: _4623e938,
    name: "warrantyClaims___cs"
  }, {
    path: "/vymena-vraceni-dekujeme",
    component: _5851d327,
    name: "warrantyClaims-thanks___cs"
  }, {
    path: "/vymena-vraceni-formular",
    component: _6f2bcd22,
    name: "warrantyClaims-form___cs"
  }, {
    path: "/vymena-vraceni-informace",
    component: _5d8b200c,
    name: "warrantyClaims-info___cs"
  }, {
    path: "/vymena-vraceni-rekapitulace",
    component: _304104d1,
    name: "warrantyClaims-recap___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _26efb061,
    name: "passwordForgotten___cs"
  }, {
    path: "/auth/proxy",
    component: _2af1e215,
    name: "auth-proxy___cs"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _7560190e,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _15c0fbbd,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _77c77d92,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _19419b9e,
    name: "checkout-importOrder___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _5b310e4c,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _3a9bbde4,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/oblibene",
    component: _4d85e160,
    name: "user-favourites___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _40a9ed89,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _5cec16c4,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/seznam-adres",
    component: _dd3c4588,
    name: "user-addressList___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _429cf001,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _2fb8d079,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/",
    component: _54488cf4,
    name: "index___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _2f51e9c2,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _22d10ef6,
    name: "user-orders-id___cs"
  }, {
    path: "/clanek/:slug",
    component: _5c7517d3,
    name: "article-slug___cs"
  }, {
    path: "/clanky/:slug",
    component: _78bfe711,
    name: "articleList-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _2529348c,
    name: "product-slug___cs"
  }, {
    path: "/stranka/:slug",
    component: _e6f0c550,
    name: "page-slug___cs"
  }, {
    path: "/*",
    component: _b128a9fe,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
