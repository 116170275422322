import { get } from 'lodash'
import { notifications } from '~/app/notifications'

export default {
  props: {
    buttonMinusLabel: {
      type: String,
      default() {
        return this.$t('AppNumber.button.minus.label')
      },
    },
    buttonPlusLabel: {
      type: String,
      default() {
        return this.$t('AppNumber.button.plus.label')
      },
    },
    decimalPosition: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initial: {
      type: [Number, String],
      default: 1,
    },
    inputLabel: {
      type: String,
      default() {
        return this.$t('AppNumber.input.label')
      },
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    enableLimitNotifications: {
      type: Boolean,
      default: false,
    },
    notificationMaxAmountReached: {
      type: String,
      default: '',
    },
    notificationMinAmountReached: {
      type: String,
      default: '',
    },
    quantityMultiplier: {
      type: Number,
      default: 1,
    },
    step: {
      type: Number,
      default: 1,
    },
    unit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: Number((this.initial / this.quantityMultiplier).toFixed(this.decimalPosition)),
    }
  },
  methods: {
    blur() {
      if (typeof this.value == 'string') {
        const regExpEnd = new RegExp('\\.$') // Remove dot on end
        this.update(Number(this.value.replace(regExpEnd, '')))
      }
    },
    change(e) {
      const regExpNumbers = new RegExp(this.decimalPosition ? '[^0-9.,]' : '[^0-9]')
      let value = e.target.value.replace(regExpNumbers, '') // Replace everything except numbers, '.' and ','
      value = value.replace('^(\\,|\\.)', '').replace(/\,/g, '.') // Remove ',' or '.' on string start, then replace ',' with '.'
      if (this.decimalPosition) {
        const regExpDecimal = new RegExp('^\\d+\\.?\\d{0,' + this.decimalPosition + '}')
        const result = value.match(regExpDecimal)
        if (result && result.length) {
          value = result[0]
        }
      }
      if (value === '00') {
        value = 0
      }
      this.update(value, true)
    },
    focus(e) {
      const nodeName = get(e, 'path[0].nodeName', false)
      if (nodeName && nodeName !== 'INPUT') {
        this.$refs.input.focus()
        this.$nextTick(() => {
          this.$refs.input.setSelectionRange(-1, -1)
        })
      }
    },
    inLimits(value) {
      if (this.enableLimitNotifications) {
        if (this.max && value > this.max) {
          this.notificationAmountReached()
          return this.max
        } else if (this.min && value < this.min) {
          this.notificationAmountReached('min')
          return this.min
        }
      }
      return value
    },
    notificationAmountReached(type) {
      const fallbackNotification = this.$t('AppNumber.' + (type || 'max') + 'AmountReached', {
        limit: type === 'min' ? this.min : this.max,
        unit: this.unit,
      })
      notifications.info(
        this.$options.filters.prepositions(
          (type === 'min' ? this.notificationMinAmountReached : this.notificationMaxAmountReached) ||
            fallbackNotification,
        ),
      )
    },
    plus() {
      const normalizer = this.normalizer,
        step = this.step * normalizer
      let value = Math.round(this.value * normalizer) // Using Math.round for fix javascript "problem" with multiplying
      value += step
      this.update(Number((value / normalizer).toFixed(this.decimalPosition)))
    },
    minus() {
      const normalizer = this.normalizer,
        step = this.step * normalizer
      let value = Math.round(this.value * normalizer) // Using Math.round for fix javascript "problem" with multiplying
      value -= step
      this.update(Number((value / normalizer).toFixed(this.decimalPosition)))
    },
    update(value, force) {
      const update = force ? value : this.inLimits(value)
      this.value = update
      this.$emit('update', update * this.quantityMultiplier)
    },
  },
  computed: {
    normalizer() {
      return this.decimalPosition ? Math.pow(10, this.decimalPosition) : 1
    },
    unitCharLength() {
      const { maxUnitLength } = this.$themeSettings.components.AppNumber || {}
      const length = this.unit.replace('(\\,|\\.)', '').length || 0
      return maxUnitLength && maxUnitLength < length ? maxUnitLength : length
    },
    valueCharLength() {
      const { maxValueLength } = this.$themeSettings.components.AppNumber || {}
      const length = this.value.toString().length || 0
      return maxValueLength && maxValueLength < length ? maxValueLength : length || 1
    },
  },
}
