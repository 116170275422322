export const state = () => ({
  warrantyClaimsData: {
    order: null,
    selectedItems: [],
    temp: [],
    priceDiff: null,
    formData: null,
    token: '',
  },
})

export const mutations = {
  SET_WARRANTY_CLAIMS_ORDER(state, order) {
    state.warrantyClaimsData.order = order
  },
  RESET_WARRANTY_CLAIMS_DATA(state) {
    state.warrantyClaimsData.order = null
    state.warrantyClaimsData.orderItems = []
    state.warrantyClaimsData.temp = []
    state.warrantyClaimsData.prices = null
    state.warrantyClaimsData.formData = null
  },
  SET_WARRANTY_CLAIMS_ITEMS(state, selectedItems) {
    state.warrantyClaimsData.selectedItems = selectedItems
  },
  SET_WARRANTY_CLAIMS_ITEMS_TEMP_ITEMS(state, data, prices) {
    state.warrantyClaimsData.temp = data
  },
  SET_WARRANTY_CLAIMS_ITEMS_TEMP_PRICE_DIFF(state, priceDiff) {
    state.warrantyClaimsData.priceDiff = priceDiff
  },
  SET_WARRANTY_CLAIMS_FORM_DATA(state, formData) {
    state.warrantyClaimsData.formData = formData
  },
  SET_WARRANTY_CLAIMS_TOKEN(state, token) {
    state.warrantyClaimsData.token = token
  },
  RESET_WARRANTY_CLAIMS_TOKEN(state) {
    state.warrantyClaimsData.token = ''
  },
}

export const getters = {
  getData: state => {
    return state.warrantyClaimsData
  },
}
