import PhoneNumber from 'awesome-phonenumber'
import VeeValidate, { Validator } from 'vee-validate'
import Vue from 'vue'
import _ from 'lodash'
import postalCodes from 'postal-codes-js'

export default ({ $axios, app }, inject) => {
  Vue.use(VeeValidate, {
    locale: app.i18n.locale,
    events: 'change|blur',
  })

  Validator.extend('full_name', {
    getMessage: field => 'The ' + field + ' is not a full name.',
    validate: value => /^(.+)( .+)+$/g.test(value),
  })

  Validator.extend('phone_number', {
    getMessage: field => 'The ' + field + ' is not a valid phone number.',
    validate: (value, args) => {
      // Validate if input contains only allowed chars
      if (!/^[\+\d]?(?:[\d-.\s()]*)$/g.test(value)) {
        return false
      }
      // Validate phone number
      if (value && args[0]) {
        let phone = new PhoneNumber(value.toString(), args[0])
        return phone ? phone.isValid() : false
      }
      return false
    },
  })

  Validator.extend('street_only', {
    getMessage: field => 'The ' + field + ' is not a street only.',
    validate: (value, args) => {
      const houseNumber = args && args[0]
      return value.indexOf(houseNumber) === -1
    },
  })

  Validator.extend('house_number', {
    getMessage: field => 'The ' + field + ' is not a house number.',
    validate: (value, args) => {
      const valueParts = value.split(' ')
      const street = args && args[0]
      return !(valueParts.length > 1 || value === street)
    },
  })

  Validator.extend('postcode', {
    getMessage: field => 'The ' + field + ' is not a zip code.',
    validate: (value, args) => {
      if (postalCodes.validate(args[0], value) === true) {
        return true
      } else {
        return false
      }
    },
  })

  Validator.extend('street_and_number', {
    getMessage: field => 'The ' + field + ' should contain street and house number.',
    validate: value => {
      return /^.*[^0-9]+.*\s+\d+.*$/.test(value)
    },
  })

  Validator.extend('tin', {
    getMessage: field => 'The ' + field + ' is not a valid tin.',
    validate: (value, args) => {
      if (args && args[0] === 'CZE') {
        try {
          let a = 0
          if (value.length == 0) return true
          if (value.length != 8) throw 1
          let b = value.split('')
          let c = 0
          for (let i = 0; i < 7; i++) a += parseInt(b[i]) * (8 - i)
          a = a % 11
          c = 11 - a
          if (a == 1) c = 0
          if (a == 0) c = 1
          if (a == 10) c = 1
          if (parseInt(b[7]) != c) throw 1
        } catch (e) {
          return false
        }
      }
      return true
    },
  })

  const customValidation = {
    messages: {
      email: field => app.i18n.t('globals.validation.messages.email', { field }),
      full_name: field => app.i18n.t('globals.validation.messages.full_name', { field }),
      phone_number: (field, args) => {
        // Get country code
        const countryCode =
          args[0] && PhoneNumber(args[0].toString()).getRegionCode()
            ? PhoneNumber(args[0].toString())
                .getRegionCode()
                .toLowerCase()
            : ''
        // Set predefined message
        let message = app.i18n.t(`globals.validation.messages.phone_number_country_${countryCode}`)
        // Set message if it's not predefined
        if (message.includes('globals.validation.messages') && countryCode.length > 0) {
          message = app.i18n.t(`globals.validation.messages.phone_number_country_other`, {
            example: PhoneNumber.getExample(countryCode).getNumber('international'),
          })
        } else if (countryCode.length === 0) {
          message = app.i18n.t(`globals.validation.messages.phone_number_country_none`)
        }
        return message
      },
      street_and_number: field => app.i18n.t('globals.validation.messages.street_and_number', { field }),
      street_only: field => app.i18n.t('globals.validation.messages.street_only', { field }),
      house_number: field => app.i18n.t('globals.validation.messages.house_number', { field }),
      postcode: field => app.i18n.t('globals.validation.messages.postcode', { field }),
      tin: field => app.i18n.t('globals.validation.messages.tin', { field }),
    },
  }

  import(`vee-validate/dist/locale/${app.i18n.locale}`).then(messages => {
    Validator.localize(app.i18n.locale, _.merge(messages, customValidation))
  })
}
