import { merge } from 'lodash'
import contentSettings from '~/sites/shop/content.settings'

const overloadSettings = {
  brandParameterId: '5f96e64eaf1204001786e798',
  components: {
    FooterContent: {
      showLocation: true,
    },
  },
  functions: {
    products_user_reviews: true,
    favourites: true, // WishList must be allowed too
    shoppingLists: true,
    ssrPropertyPicker: true,
    wishList: true,
  },
}

export default merge(contentSettings, overloadSettings)
