import { get } from 'lodash'
import ProductAvailability from '~/themes/base/components/product/ProductAvailability'

export default {
  mixins: [ProductAvailability],
  computed: {
    text() {
      let availableIn =
        this.product.availability.delayDays > 0
          ? ' ' + this.$tc('ProductAvailability.availableIn', this.product.availability.delayDays)
          : ''

      if (this.limitedAmount > 1 && this.limitedAmount < 5 && this.$i18n.locale === 'en') {
        return this.limitedAmount + ' pcs in stock'
      }

      return this.product.availability.isAvailable
        ? this.$themeSettings.components.ProductAvailability.showTextsFromBackend
          ? this.product.availability.availability
          : this.$tc('ProductAvailability.availability', this.limitedAmount)
        : this.$themeSettings.components.ProductAvailability.showTextsFromBackend
          ? get(this.product, `content.product_availability[${this.$i18n.locale}]`) ||
            this.product.availability.soldAvailability
          : this.$tc('ProductAvailability.availability', 0) + availableIn
    },
  },
}
