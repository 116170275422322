import WishListIcon from '~/themes/base/components/product/WishListIcon'

export default {
  mixins: [WishListIcon],
  methods: {
    async addToWishList() {
      this.startLoading()
      await this.$store.dispatch('user/ADD_ITEM_TO_SHOPPING_LIST', { variant: this.variant })
      await this.$store.dispatch('user/FETCH_WISHLIST')
      this.stopLoading()
    },
  },
}
