import { merge } from 'lodash'
import themeSettings from '~/themes/base/settings'

const overloadSettings = {
  checkout: {
    enableCartDataValidation: true,
  },
  mobileDesktopBreakingPoint: 'lg',
  selectBoxIcon: 'down-arrow',
  components: {
    ArticleBox: {
      articleInfo: {
        author: {
          enable: false,
        },
        enable: true,
      },
      image: {
        height: 344, // Change SASSs variable $ArticleBox-image-height
        width: 550, // Change SASSs variable $ArticleBox-image-width
      },
      linkReadMore: {
        button: 'outline-primary btn-sm-block', // link, primary or secondary
        enable: true,
      },
    },
    ArticleList: {
      perLine: {
        xs: 1,
        lg: 2,
      },
      perPage: 14,
    },
    Breadcrumbs: {
      separatorIcon: 'right',
    },
    BreadcrumbFirstItem: {
      icon: 'home',
      showIcon: false,
      showText: true,
    },
    Carousel: {
      showNavigationOnMobile: true,
      showPartOfNextProduct: false,
    },
    CatalogFiltersBox: {
      toggleIcon: {
        closed: 'plus',
        opened: 'minus',
      },
    },
    CatalogFiltersMobile: {
      button: 'link', // primary, secondary, cta
      icon: {
        close: 'cross',
        open: 'filter',
      },
    },
    CatalogSorting: {
      options: ['DefaultSort', 'PriceSortASC', 'PriceSortDESC'], // See available options in CatalogSorting.js
    },
    CartInfoDropDownContent: {
      showDeliveryDate: false,
    },
    CatalogSubCategories: {
      enable: false,
    },
    CheckoutItemLine: {
      showDeliveryDate: false,
      showOriginalPrice: true,
      showPercentageDiscount: true,
    },
    CheckoutMethodsShipper: {
      showDeliveryDate: false,
      enableDPDForCountries: ['CZE'],
      enableZasilkovnaForCountries: ['CZE', 'SVK'],
    },
    ContactBox: {
      button: {
        style: 'cta btn-block',
      },
    },
    ContactBoxImage: {
      image: {
        width: 80,
        height: 80,
      },
    },
    ContentBuilder: {
      products: {
        perLine: {
          xs: 1,
          md: 2,
          lg: 3,
        },
      },
    },
    ContentHeader: {
      image: {
        width: 1200,
        height: 480,
        required: false,
      },
    },
    CurrencySwitcher: {
      displayNameType: 'code', // code, name, symbol
      enable: true, // Set `$currencySwitcherEnable` in `_variables.scss`
      icon: 'down-chevron',
    },
    FrequentQuestionBox: {
      enableAccordion: true,
      showFaqCategoryIcon: true,
    },
    HomepageBrands: {
      brandBox: {
        imageClass: 'BrandBox__image',
      },
      perLine: {
        xs: 3,
        md: 3,
        lg: 3,
      },
    },
    LightboxGallery: {
      thumbnails: {
        position: 'left',
      },
    },
    MainMenu: {
      dropDownIndicatorIcon: 'down-chevron',
    },
    MainMenuDropdownContent: {
      content: {
        type: 'link', // SubCategoryBox, link
      },
      direction: 'column',
      showHeading: false,
      subcategoriesCountPerLine: {
        md: 1,
        lg: 1,
        xl: 1,
      },
    },
    MainMenuMobile: {
      breakpoint: 'lg',
      showSubLevelOpenEffect: 'dropdown', // 'dropdown', 'slideToSide'
    },
    NewsletterForm: {
      button: 'cta',
    },
    OrderBox: {
      enablePaymentButton: true,
      showItemsAmount: true,
    },
    PageArticleDetail: {
      articleInfo: {
        enable: true,
      },
      sidebar: {
        enable: true,
        position: 'right', // `left`, `right`
      },
    },
    PageArticleList: {
      sidebar: {
        enable: true,
        position: 'right', // `left`, `right`
      },
    },
    PageArticleListCategory: {
      sidebar: {
        enable: true,
        position: 'right', // `left`, `right`
      },
    },
    PageCatalog: {
      Pagination: {
        bottom: {
          showButtonNextToPagination: true,
        },
        top: {
          showOnDesktop: false,
          showOnMobile: false,
        },
      },
      perLine: {
        xs: 1,
        sm: 2,
        md: 3,
        lg: 3,
        xl: 4,
      },
      perPage: 24,
      showCatalogHelp: false,
      showContactBox: false,
      sidebar: {
        columnSize: {
          lg: 6,
          xl: 4,
        },
      },
    },
    PageCheckoutItems: {
      recommendedProductsInCart: {
        enable: true,
        perLine: {
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        },
      },
      showLoginLineHook: true,
    },
    PageCheckoutInfo: {
      newsletter: {
        defaultValue: false,
        showCheckboxForLoggedUser: true,
      },
      input: {
        billingHouseNumber: {
          enable: true,
        },
        houseNumber: {
          enable: true,
        },
        noteForShipper: {
          enable: true,
        },
        vatin: {
          disableInputForCountries: ['SVK'],
        },
      },
      privacyPolicyLink: {
        showRequiredSign: true,
      },
    },
    PageCheckoutMethods: {
      enableCountriesSwitcher: true,
      enableCurrencySwitcher: true,
    },
    PageFaq: {
      categories: {
        showSidebar: false,
        showIconBoxes: true,
      },
    },
    PageHomepage: {
      mainHeadingPosition: '', // aboveSlider, belowSlider
      mostSoldProducts: {
        perLine: {
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        },
      },
      newestProducts: {
        perLine: {
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        },
      },
      showHomepageBranches: false,
      showHomepageBrands: true,
      showHomepageTextBlock: false,
      showProductsRecommended: false,
    },
    PageHomepageMainHeading: {
      enable: false,
    },
    PageLogin: {
      registrationInfoButtonType: 'primary btn-block',
    },
    PageProductDetail: {
      alternativeProducts: {
        enable: false,
      },
      recommendedProducts: {
        forceEnable: true,
        perLine: {
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        },
      },
      reviews: {
        enable: true,
      },
      showAvailabilityInWarehouses: false,
      showAvailabilityInWarehousesInDeliveryOptions: false,
      showColorVariants: true,
      showDeliveryOptions: false,
    },
    PageRegister: {
      disableVatinInputForCountries: ['SVK'],
      showSmsMarketingCheckbox: true,
      houseNumber: {
        enable: true,
      },
    },
    PageTextPage: {
      contactBox: {
        enable: false,
      },
      sidebar: {
        enable: false,
      },
    },
    PageUserOrderDetail: {
      enablePaymentButton: true,
    },
    PageUserSettings: {
      smsMarketing: {
        showCheckbox: true,
      },
    },
    Pagination: {
      button: {
        enable: false,
      },
      showPrevNextText: false,
    },
    ProductAvailability: {
      showTextsFromBackend: true,
    },
    ProductBox: {
      badge: {
        color: 'secondary', // see tags color palette + primary color
        shape: 'rectangle', // circle only
      },
      imageHeight: 550,
      imageWidth: 550,
      showAvailability: false,
      showDescription: false,
      showVariants: false,
    },
    ProductDetailImages: {
      badge: {
        color: 'secondary',
        shape: 'rectangle',
      },
      image: {
        height: 570,
        width: 570,
      },
      thumbnail: {
        height: 120,
        width: 120,
      },
    },
    ProductsVisited: {
      enable: false,
    },
    RangeSlider: {
      sliderDotSize: 16,
      sliderHeight: 2,
    },
    Slider: {
      cmsTextContent: {
        enable: true,
        button: 'primary btn-lg', // 'transparent', utility class ['primary'/'secondary'...] or leave empty to display as link
      },
      image: {
        width: 1280, // Change SASSs variable $Slider-image-width
        height: 630, // Change SASSs variable $Slider-image-height
        srcsetBreakpoints: [
          // Objects in array must be ordered from largest to smallest breakpoint because browsers accept first suitable option.
          { viewportMinWidth: 1280, imageWidth: 1600 },
          { viewportMinWidth: 1024, imageWidth: 1280 },
          { viewportMinWidth: 768, imageWidth: 1024 },
          { viewportMinWidth: 480, imageWidth: 768 },
          { viewportMinWidth: 320, imageWidth: 480 },
        ],
      },
      showCirclePagination: true,
      showNumericPaginationOnMobile: false,
      showTimerIndicator: false,
      showTabs: false, // If false, truncate SASS variable $Slider-with-tabs-breakpoints
    },
    SocialNetworkLinks: {
      showLabel: false,
      showNetworkName: false,
    },
    StarRating: {
      activeColor: '#ba933e',
      inactiveColor: '#ececec',
    },
    Sticky: {
      enable: true,
    },
    TopBanner: {
      alignContent: 'center',
      showIcon: false,
    },
    TopCategory: {
      image: {
        crop: true,
        height: 320,
        width: 320,
      },
    },
    UniqueSellingPoints: {
      enable: false,
    },
    WarrantyClaimsFormItem: {
      image: {
        height: 100,
        width: 100,
      },
    },
  },
  layouts: {
    LayoutBase: {
      headerStickyEnable: true,
      showHeaderTop: false,
      showMainMenu: false,
      showNewsletter: false,
    },
    LayoutCheckout: {
      showFooterBottom: false,
    },
    LayoutUser: {
      showContactForm: false,
      showNewsletter: false,
      sidebarPosition: 'left',
      verticalMenuVariant: 'user',
      LogoutButton: {
        button: 'outline-primary',
      },
    },
  },
}

export default merge(themeSettings, overloadSettings)
