import { filter, find, map } from 'lodash'
import gql from 'graphql-tag'

export const actions = {
  async FETCH_UPSELL_ITEMS({ commit, dispatch }) {
    const client = this.app.apolloProvider.defaultClient
    const resultUpsell = await client.query({
      query: gql`
        query {
          upsell_cart_item(filters: { active: true }) {
            entities {
              active
              title
              domain_enabled
              description
              quantity_as_price
              lowest_quantity
              product {
                entityId
              }
            }
            total
            error {
              statusCode
            }
          }
        }
      `,
    })
    const upsellItems = resultUpsell.data.upsell_cart_item.entities
    const upsellProducts = await dispatch(
      'product/LOAD_PRODUCTS',
      {
        ids: filter(map(upsellItems, entity => (entity.product[0] || {}).entityId), id => id),
      },
      { root: true },
    )
    const parsedItems = map(upsellItems, item => {
      const product = find(upsellProducts, product => product.id === +item.product[0].entityId)

      if (product) {
        item.product = product
      }

      return item
    })

    commit('SET_ITEMS', parsedItems)
  },
}

export const mutations = {
  SET_ITEMS(state, items) {
    state.items = items
  },
}

export const state = () => ({
  items: [],
})

export const getters = {
  upsellItems: state => {
    return state.items
  },
}
