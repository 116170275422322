import ProductBox from '~/themes/base/components/product/ProductBox'

export default {
  extends: ProductBox,
  computed: {
    percentageDiscount() {
      return Math.round(this.activeVariant.prices.percentageDiscount)
    },
  },
}
