import LayoutBase from '~/themes/base/components/shop/LayoutBase/LayoutBase.js'
import LayoutVerticalSidebar from '@theme/components/shop/LayoutVerticalSidebar'
import ScrollToTop from '@theme/components/utils/ScrollToTop'

export default {
  components: {
    LayoutVerticalSidebar,
    ScrollToTop,
  },
  mixins: [LayoutBase],
  props: {
    h1InHeader: false,
  },
}
