import _ from 'lodash'

export default {
  props: {
    position: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      socialNetworkLinks: null,
    }
  },
  mounted() {
    this.socialNetworkLinks = _.get(this.$store, 'state.globals.footer.social_links')
  },
}
