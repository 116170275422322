import AppNumber from '~/themes/base/components/atom/AppNumber'

export default {
  mixins: [AppNumber],
  methods: {
    setElementValue(val) {
      this.$refs.input.value = val
    },
  },
}
