import gql from 'graphql-tag'

export const actions = {
  async LOAD_HOMEPAGE_BANNERS() {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            banner_wide(filters: { enabled: true }) {
              entities {
                banner_wide_button_text
                banner_wide_text
                banner_wide_text_2
                main_image {
                  path
                  alt
                }
                main_image_translatable {
                  path
                  alt
                }
                main_image_mobile {
                  path
                  alt
                }
                title
                url
                weight
                time_limited
                time_limit_start
                time_limit_end
              }
            }
          }
        `,
      })
      return query.data.banner_wide.entities
        .filter(banner => {
          if (!banner.time_limited) {
            return true
          } else if (banner.time_limited && banner.time_limit_start !== null && banner.time_limit_end !== null) {
            return (
              new Date(banner.time_limit_start).valueOf() < Date.now() &&
              Date.now() < new Date(banner.time_limit_end).valueOf()
            )
          }
          return false
        })
        .sort((a, b) => (b.weight || 0) - (a.weight || 0))
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_INFO_BOXES() {
    try {
      let client = this.app.apolloProvider.defaultClient
      const infoBoxes = await client.query({
        query: gql`
          query {
            info_box {
              entities {
                box_color
                order
                text
              }
            }
          }
        `,
      })
      if (infoBoxes.data.info_box && infoBoxes.data.info_box.entities) {
        return infoBoxes.data.info_box.entities.filter(item => item && item.box_color && item.box_color.length > 0)
      }
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_SMALL_BANNERS() {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            banner_small {
              entities {
                title
                link {
                  ... on link_embedded {
                    title
                    link_type
                    custom_link
                    category_link {
                      entity {
                        ... on StoreCategory {
                          data
                        }
                      }
                    }
                  }
                }
                main_image {
                  path
                  alt
                }
                domain_enabled
                _id
              }
            }
          }
        `,
      })
      return query.data.banner_small.entities
    } catch (e) {
      console.error(e)
    }
  },
}
