import TopCategory from '~/themes/base/components/category/TopCategory/TopCategory.js'

export default {
  mixins: [TopCategory],
  props: {
    loopIndex: Number,
  },
  data() {
    return {
      imageSize:
        this.loopIndex > 0
          ? this.$themeSettings.components.TopCategory.image.width
          : 2 * this.$themeSettings.components.TopCategory.image.width,
    }
  },
}
