<script>
import Wysiwyg from '~/themes/base/components/utils/Wysiwyg'
export default {
  extends: Wysiwyg,
}
</script>

<style lang="scss">
.Wysiwyg ul li::before {
  font-size: 3em;
  line-height: 1rem;
  margin-left: -0.55em;
}
</style>
