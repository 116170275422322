var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ProductBox",on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('nuxt-link',{staticClass:"ProductBox__link",attrs:{"title":_vm.name,"to":_vm.productPath},nativeOn:{"click":function($event){return _vm.clicked.apply(null, arguments)}}},[_c('div',{staticClass:"ProductBox__imageWrapper",class:[
        {'ProductBox__imageWrapper--hasHoverImage': _vm.productBoxHoverImage},
        `order-${_vm.$themeSettings.components.ProductBox.contentOrder.image}`,
      ]},[_c('AppImage',{key:_vm.activeVariant.mainImage,attrs:{"src":_vm.activeVariant.mainImage || _vm.product.mainImage,"width":_vm.$themeSettings.components.ProductBox.imageWidth,"height":_vm.$themeSettings.components.ProductBox.imageHeight,"alt":_vm.productImageAlternateText({ variant: _vm.activeVariant }),"upscale":true,"image-class":"ProductBox__image img-responsive"}}),(_vm.productBoxHoverImage)?_c('AppImage',{attrs:{"src":_vm.productBoxHoverImage,"width":_vm.$themeSettings.components.ProductBox.imageWidth,"height":_vm.$themeSettings.components.ProductBox.imageHeight,"alt":_vm.productImageAlternateText({ variant: _vm.activeVariant }),"upscale":true,"image-class":"ProductBox__hoverImage img-responsive"}}):_vm._e(),(_vm.$themeSettings.components.ProductBox.showAddToCartButton && !(_vm.activeVariant.availability.amountMin > 1 || _vm.activeVariant.prices.quantityPrices.length > 0))?_c('AddToCart',{staticClass:"d-print-none",attrs:{"product":_vm.product,"app-button-class":'btn btn-block btn-' + _vm.$themeSettings.components.ProductBox.addToCartButton,"enable-modal-to-select-variant":_vm.$themeSettings.components.ProductBox.enableSwitchVariant === true ? false : true,"text":_vm.addToCartButtonText,"unit":_vm.$t('globals.defaultProductUnit'),"variant":_vm.activeVariant,"app-button-tag":"span"}},[_vm._v(" "+_vm._s(_vm.$t('ProductBox.button'))+" ")]):_vm._e()],1),_c('div',{staticClass:"ProductBox__wishlist"},[(_vm.$contentSettings.functions.wishList)?_c('WishListIcon',{attrs:{"variant":_vm.activeVariant}}):_vm._e()],1),(_vm.percentageDiscount > 0 || (_vm.productTags && _vm.productTags.length > 0))?_c('div',{staticClass:"ProductBox__tagsAndBadges"},[_vm._l((_vm.productTags),function(tag){return _c('Tag',{key:tag.text,class:'Tag--' + tag.color},[_vm._v(" "+_vm._s(tag.text)+" ")])}),(_vm.percentageDiscount > 0)?_c('Badge',{class:[
          `Badge--${_vm.$themeSettings.components.ProductBox.badge.color}`,
          `Badge--${_vm.$themeSettings.components.ProductBox.badge.shape}`
        ]},[_c('span',[_vm._v("−"+_vm._s(_vm.percentageDiscount)+" %")])]):_vm._e()],2):_vm._e(),_c('div',{staticClass:"ProductBox__name",class:`order-${_vm.$themeSettings.components.ProductBox.contentOrder.image}`},[_c('AppTitle',{attrs:{"heading-level":_vm.headingLevel}},[(_vm.titleHighlightsComponent)?_c(_vm.titleHighlightsComponent,{tag:"component",attrs:{"queries":_vm.titleHighlights}},[_vm._v(_vm._s(_vm.name))]):[_vm._v(_vm._s(_vm.name))]],2)],1),(_vm.$themeSettings.components.ProductBox.showCategory)?_c('div',{staticClass:"ProductBox__category",class:`order-${_vm.$themeSettings.components.ProductBox.contentOrder.category}`},[_vm._v(" "+_vm._s(_vm.product.categoryName)+" ")]):_vm._e(),(_vm.$themeSettings.components.ProductBox.showAvailability)?_c('div',{staticClass:"row align-items-sm-center",class:`order-${_vm.$themeSettings.components.ProductBox.contentOrder.availability}`},[_c('div',{staticClass:"col-24 col-sm mb-2 print-mb-0"},[_c('ProductAvailability',{staticClass:"ProductBox__availability",class:[
            _vm.product.availability.isAvailable
              ? 'text-success'
              : 'text-danger'
          ],attrs:{"product":_vm.activeVariant}})],1),_c('div',{staticClass:"col-24 col-sm-auto mb-2 print-mb-0"},[(_vm.activeVariant.availability.isAvailable && _vm.$store.state.globals.fastestShipper)?_c('DeliveryDate',{staticClass:"ProductBox__nearestDeliveryDate",attrs:{"shipper":_vm.$store.state.globals.fastestShipper,"product":_vm.product}}):_vm._e(),(_vm.$themeSettings.components.ProductBox.showCompare)?_c('CompareButton',{attrs:{"product":_vm.product,"show-text":false}}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"row",class:[
        `order-${_vm.$themeSettings.components.ProductBox.contentOrder.price}`,
        _vm.product.prices.unitWithTax <= 99999 ? 'justify-content-between flex-sm-nowrap' : 'flex-column',
      ]},[_c('div',{staticClass:"col-auto mb-2 print-mb-0"},[(
            _vm.$themeSettings.components.ProductBox.showB2CPriceForB2BPartner &&
              _vm.isBusinessUser && _vm.activeVariant.defaultPrices.originalWithTax > _vm.activeVariant.prices.unitWithTax
          )?_c('div',{staticClass:"ProductBox__priceB2C"},[_vm._v(" "+_vm._s(_vm.$currencyFormat(_vm.activeVariant.defaultPrices.originalWithTax))+" ")]):_vm._e(),(_vm.activeVariant.prices.inDiscount)?_c('div',{staticClass:"ProductBox__originalPrice"},[_vm._v(" "+_vm._s(_vm.$currencyFormat(_vm.activeVariant.prices.originalWithTax))+" ")]):_vm._e(),_c('div',{staticClass:"ProductBox__priceInclVat",class:{'ProductBox__priceInclVat--inDiscount': _vm.activeVariant.prices.inDiscount}},[_vm._v(" "+_vm._s(_vm.$currencyFormat(_vm.activeVariant.prices.unitWithTax))+" ")])])]),(_vm.$themeSettings.components.ProductBox.showDescription)?_c('div',{staticClass:"row align-items-sm-center justify-content-sm-between",class:`order-${_vm.$themeSettings.components.ProductBox.contentOrder.description}`},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"ProductBox__description"},[_vm._v(" "+_vm._s(_vm.product.perex)+" ")])])]):_vm._e(),(_vm.product.hasVariants && _vm.$themeSettings.components.ProductBox.showVariants)?_c('ProductBoxVariants',{class:`order-${_vm.$themeSettings.components.ProductBox.contentOrder.variants}`,attrs:{"product":_vm.product,"active-variant-id":_vm.activeVariant.id,"use-link":_vm.$themeSettings.components.ProductBox.enableSwitchVariant === true ? false : true},on:{"changeVariant":_vm.selectVariant}}):_vm._e(),(_vm.$themeSettings.components.ProductBox.showColorVariants)?_c('ProductBoxColorVariants',{class:`order-${_vm.$themeSettings.components.ProductBox.contentOrder.colorVariants}`,attrs:{"active":_vm.hover,"product":_vm.product}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }