export default function({ app }) {
  const cookieBotUrl = 'https://consent.cookiebot.com/uc.js'
  app.head.script.push({
    src: cookieBotUrl,
    async: true,
    ['data-cbid']: '519fa439-5992-42c7-b8db-e0b9ee482f13',
    ['data-blockingmode']: 'auto',
    type: 'text/javascript',
    id: 'Cookiebot',
  })
}
