export default {
  data() {
    return {
      isVisible: false,
      topOffset: null,
    }
  },
  mounted() {
    this.topOffset = document.body.offsetHeight * 0.75
    window.addEventListener('scroll', () => {
      this.isVisible = document.body.scrollTop > this.topOffset || document.documentElement.scrollTop > this.topOffset
    })
  },
  methods: {
    scrollToTop() {
      this.$scrollTo('#HeaderMain')
    },
  },
}
